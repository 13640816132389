//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25TaskViewComponent } from "./s25.task.view.component";
import { S25OptModule } from "../s25-opt/s25.opt.module";
import { S25TaskModule } from "../s25-task/s25.task.module";

@NgModule({
    declarations: [S25TaskViewComponent],
    imports: [CommonModule, FormsModule, S25OptModule, S25TaskModule],
    providers: [S25TaskViewComponent],
    exports: [S25TaskViewComponent],
})
export class S25TaskViewModule {
    constructor() {}
}
